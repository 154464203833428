/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

export const Credits = ({ creditText, creditLogos, additionalInfo }) => {
  function hasCredits(text, logos, additional) {
    return text !== '' || logos.length > 0 || additional !== '';
  }

  if (hasCredits(creditText, creditLogos, additionalInfo)) {
    return (
      <div className="credits container row">
        <div className="col-sm-2 hidden-xs col" />
        <div className="col-sm-7 col-xs-6 col">
          {Boolean(additionalInfo) && (
          <div
            className="content credits-additional additional-info"
            dangerouslySetInnerHTML={{
              __html: additionalInfo,
            }}
          />
          )}
          {Boolean(creditText) && (
          <div
            className="content credits-text sans--small"
            dangerouslySetInnerHTML={{
              __html: creditText,
            }}
          />
          )}
          {creditLogos.length > 0 && (
          <div className="credits-logos">
            {creditLogos.map(({ fixed, alt }) => (
              <Img className="credit-logo" fixed={fixed} alt={alt} key={Math.random()} />
            ))}
          </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

Credits.propTypes = {
  creditText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  creditLogos: PropTypes.array.isRequired,
  additionalInfo: PropTypes.string,
};

Credits.defaultProps = {
  additionalInfo: '',
};
