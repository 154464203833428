/* eslint-disable react/no-danger */
import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { DividerBlock } from '../components/dividerBlock';
import { Layout } from '../components/layout';
import { Credits } from '../components/credits';
import ImageFadeIn from "react-image-fade-in"

const Education = ({ data }) => {
  const { education } = data;

  return (
    <Layout bgcolor={education.backgroundColor} wrapperClass="education" accentColor={education.accentColor}>
      <HelmetDatoCms seo={education.seoMetaTags} />
      <div className="main-block">
        <div className="main-title col">
          <h1 className="gothic--large large-title">{education.title}</h1>
        </div>
        <div className="main-image col">
					<ImageFadeIn
						src={education.featuredImage.url}
						alt={education.featuredImage.alt}
						className="featured-title-image"
					/>
          <p
            className="caption"
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: education.featuredImage.title,
            }}
          />
        </div>
        <div className="main-intro row">
          <div
            className="intro-text col sans--regular"
            dangerouslySetInnerHTML={{ __html: education.introduction }}
          />
          <div className="intro-divider col">
            <DividerBlock accentColor={education.accentColor} />
          </div>
        </div>
        <div
          className="main-text content col"
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: education.description,
          }}
        />
      </div>
      {education.programs.length > 0 && (
        <div className="education-programs container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">{education.sideHeading}</span>
          </div>
          <div className="col-sm-12 col-xs-6">
            {education.programs.map((
              {
                title,
                excerpt,
                slug,
                id,
                images,
              },
            ) => (
              <Link className="itr-wrap" to={`/programs/${slug}`} key={id}>
                <div className="itr image-text-row spacing-0">
                  <div className="image-text-row-image-wrapper col">
                    <Img fluid={{ ...images[0]?.fluid }} />
                  </div>
                  <div className="image-text-row-text-wrapper col">
                    <h3 className="sans--medium">{title}</h3>
                    {excerpt !== ''
                      && (
                      <div
                        className="excerpt"
                      // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: excerpt,
                        }}
                      />
                      )}
                    <div className="read-more">
                      Read More
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
      <Credits creditText={education.credit} creditLogos={education.creditLogos} />
      <div className="col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col spacing-0">
        <DividerBlock accentColor={education.accentColor} />
      </div>
    </Layout>
  );
};

Education.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default Education;

export const query = graphql`
  query EducationQuery {
    education: datoCmsEducation {
      accentColor {
        hex
      }
      backgroundColor
      creditLogos {
        alt
        fixed(height: 50) {
          ...GatsbyDatoCmsFixed_noBase64
        }
      }
      credit
      description
      featuredImage {
				url(imgixParams: { w: "900", ar: "5:3", fit: "crop", crop: "focalpoint" })
				alt
      }
      introduction
      title
      sideHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      programs {
        title
        excerpt
        slug
        id
        images {
          fluid(maxHeight: 280) {
            ...GatsbyDatoCmsSizes_noBase64
          }
        }
      }
    }
  }
`;
